// src/hooks/useControlHandlers.js
import { useSound } from '../hooks/useSound';

export const useControlHandlers = (
    setClueStates,
    setAvailableSyllables,
    setSelectedSyllable,
    availableSyllables,
    selectedSyllable,
    isSoundOn
  ) => {

    const playSound = useSound();
    
    const shuffleArray = (array) => {
      return array.sort(() => Math.random() - 0.5);
    };
  
    const handleShuffle = () => {
      if (isSoundOn) playSound("click");
      setAvailableSyllables(shuffleArray([...availableSyllables]));
    };
  
    const handleSort = () => {

      if (isSoundOn) playSound("click");
      const sortedSyllables = [...availableSyllables].sort((a, b) =>
        a.text.localeCompare(b.text)
      );
      setAvailableSyllables(sortedSyllables);
    };
  
    const handleClear = () => {

      if (isSoundOn) playSound("click"); 
      setClueStates((prevClueStates) =>
        prevClueStates.map((clue) => ({
          ...clue,
          filledSyllables: Array(clue.syllable_count).fill(""),
        }))
      );
      setAvailableSyllables((prevSyllables) =>
        prevSyllables.map((syllable) => ({ ...syllable, disabled: false }))
      );
  
      const currentClueIndex = selectedSyllable
        ? parseInt(selectedSyllable.split("-")[0])
        : 0;
      setSelectedSyllable(`${currentClueIndex}-0`);
    };
  
    return {
      handleShuffle,
      handleSort,
      handleClear,
    };
  };
  