// src/components/GameWonModal.js

import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Modal, IconButton, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/IosShare';
import HistoryIcon from '@mui/icons-material/History';
import TodayIcon from '@mui/icons-material/Today';
import AppsIcon from '@mui/icons-material/Apps';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { useThemeContext } from '../contexts/ThemeContext';
import { capitalizeFirstLetter, formatTime, calculateCountdown, getReferrer } from '../utils/utilityFunctions';
import Confetti from 'react-confetti';
import { IframeContext } from '../contexts/IframeContext'; 
import { useSound } from '../hooks/useSound';

const GameWonModal = ({
  open,
  onClose,
  timer,
  puzzleNumber,
  puzzleType = "daily",
  handlePreviousPuzzle,
  handleNextPuzzle,
  isPrevDisabled,
  isNextDisabled,
  prevPuzzleNumber,
  nextPuzzleNumber,
  isPrevPuzzleCompleted,
  isNextPuzzleCompleted,
}) => {
  const { currentTheme, isSoundOn } = useThemeContext();
  const { isNotInIframe } = useContext(IframeContext); 
  const navigate = useNavigate();
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiPieces, setConfettiPieces] = useState(200);
  const [countdown, setCountdown] = useState('');

  const buyMeCoffeeUrl = "https://ko-fi.com/pauleyco";
  const playSound = useSound();

  useEffect(() => {
    if (open) {
      setShowConfetti(true);
      setConfettiPieces(200);
      setTimeout(() => {
        setConfettiPieces(0);
      }, 3000);
    }

    const interval = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => clearInterval(interval);
  }, [open]);

  const handleNavigation = (route) => {
    navigate(route);
    onClose();
  };

  const handleShareClick = () => {
    if (isSoundOn) playSound("toggle");
    const sharePuzzleTypeText = puzzleType === 'pack' 
    ? `Pack #${puzzleNumber}` 
    : `${capitalizeFirstLetter(puzzleType)} #${puzzleNumber}`;
    const shareText = `${sharePuzzleTypeText}\n---- --- ----\n⏱️: ${formatTime(timer)}\n---- --- ----\n\nwww.syllacrostic.com`;
  
    // Copy the shareText to the clipboard
    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(shareText);
        console.log('Text copied to clipboard');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    };
  
    // First copy to clipboard, then attempt to use the share API if available
    copyToClipboard();
  
    if (navigator.share) {
      navigator.share({title: 'Syllacrostic', text: shareText})
      .then(() => console.log('Successful share'))
      .catch((error) => console.error('Error sharing:', error));
    } else {
      alert("Results copied to clipboard! You can now manually share.");
    }
  };
  

  const handleSubmitScoreClick = () => {
    const maxScore = 3000; 
    const maxTime = 30 * 60; // 30 minutes in seconds
  
    // Use the timer directly to calculate the time elapsed in seconds
    const timeElapsed = timer; // timer is already in seconds
  
    // Calculate the score based on the elapsed time
    let score = Math.floor(maxScore - (timeElapsed * (maxScore / maxTime))); 
    const reason = 'Daily puzzle solved';
  
    // Ensure the score is between 1 and 2999
    if (!score || score < 0 || score > 2999 || isNaN(score)) {
      score = 1; 
    }
  
    console.log("Submitting Score:", score);
  
    // Submit the score to ArenaHelper
    if (window.ArenaHelper && typeof window.ArenaHelper.getInstance === "function") {
      const arenaHelper = window.ArenaHelper.getInstance();
  
      if (typeof arenaHelper.handleGameEnd === 'function') {
        arenaHelper.handleGameEnd(score, reason);
        console.log("Score submitted to Arkadium:", score, reason);
      } else {
        console.error("ArenaHelper's handleGameEnd method is not available.");
      }
    } else {
      console.error("ArenaHelper is not available.");
    }
  };
  

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    background: currentTheme.secondaryColor, // New gradient background
    color: currentTheme.textColor,
    width: '100%',
    mb: 1,
    padding: '10px 15px', // Increased padding
    borderRadius: '10px', // Slightly increased border radius
    fontWeight: "bold",
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', // Added box shadow for depth
    transition: 'all 0.3s ease-in-out', // Smooth transition
    "&:hover": {
      transform: "scale(1.05)", // Slightly enlarge on hover
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.25)', // Stronger shadow on hover
    },
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ overflow: 'hidden' }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: currentTheme.primaryColor,
          border: `2px solid ${currentTheme.borderColor}`,
          boxShadow: 24,
          p: 4,
          borderRadius: "12px", // Updated border radius
          width: "350px",
          maxWidth: "80%",
          textAlign: "center",
          maxHeight: "75%",
          overflow: "auto",
        }}
      >
        {showConfetti && (
          <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>
            <Confetti numberOfPieces={confettiPieces} width={window.innerWidth} height={window.innerHeight} />
          </Box>
        )}

        {/* Logo and Close Button */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <img
            src={`${process.env.PUBLIC_URL}/logo512x512.png`}
            alt="Logo"
            style={{ height: 30, filter: currentTheme.logoInvertColor }}
          />
          <IconButton onClick={onClose} sx={{ color: currentTheme.textColor }}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Title and Time Section */}
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 2, backgroundColor: currentTheme.secondaryColor, borderRadius: '8px' }}>
          <Typography
            id="modal-title"
            variant="h5"
            component="h2"
            sx={{ fontWeight: "bold", color: currentTheme.textColor, mb: 0.5, mt: 0.5 }}
          >
            {puzzleType === 'pack' ? `Pack Puzzle #${puzzleNumber}` : `${capitalizeFirstLetter(puzzleType)} #${puzzleNumber}`}
          </Typography>
          <Box
            sx={{
              width: '90%',
              height: '2px',
              backgroundColor: currentTheme.textColor,
            }}
          />
          <Typography
            id="modal-description"
            sx={{ mt: 0.5, mb: 0.5, color: currentTheme.textColor, fontWeight: "bold", fontSize: "1.2em" }}
          >
            Time: {formatTime(timer)}
          </Typography>
        </Box>

        {/* Countdown and Share Button */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, backgroundColor: currentTheme.secondaryColor, borderRadius: '8px'  }}>
          {/* Left side: Next Puzzle and Countdown */}
          <Box sx={{ flex: 1, textAlign: 'center' }}>
            <Typography sx={{ fontWeight: "bold", color: currentTheme.textColor, ml: 1}}>
              Next Puzzle
            </Typography>
            <Typography sx={{ color: currentTheme.textColor, ml: 1 }}>
              {countdown}
            </Typography>
          </Box>

          {/* Right side: Share button */}
          <Button
            sx={{
              ...buttonStyle,
              flex: 1,
              padding: '5px', 
              maxWidth: '45%',
              margin: 1,
              backgroundColor: "#FFD700", 
              color: "black",
              alignItems: 'center',
              "&:hover": {
                backgroundColor: "#FFC000",
              },
            }}
            onClick={isNotInIframe ? handleShareClick : handleSubmitScoreClick}
          >
           {isNotInIframe ? (
              <>
                <ShareIcon />
                Share
              </>
            ) : (
              <>
                <ShareIcon />
                Submit Score
              </>
            )}
          </Button>
          </Box>

        {/* Puzzle Type Label */}
        {puzzleType !== 'daily' && (
          <Typography sx={{ mb: 1, fontWeight: "bold", color: currentTheme.textColor }}>
            {puzzleType === 'archive' ? 'Archive' : 'Pack'} Puzzles
          </Typography>
        )}

        {/* Next and Previous Puzzle Buttons */}
        {puzzleType !== 'daily' && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 2, gap: "10px" }}>
            <Button
              onClick={handlePreviousPuzzle}
              disabled={isPrevDisabled}
              sx={{
                ...buttonStyle,
                border: `1px solid ${currentTheme.borderColor}`,
                flex: "1 1 45%",
                backgroundColor: isPrevDisabled ? "#d3d3d3" : "#2196f3",
                "&:hover": {
                  backgroundColor: isPrevDisabled ? "#d3d3d3" : "#1976d2",
                },
              }}
            >
              {/* Flex container to evenly space icons and text */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <ArrowBackIcon />
                #{prevPuzzleNumber}
                {isPrevPuzzleCompleted && (
                  <CheckCircleIcon sx={{ color: 'green' }} />
                )}
              </Box>
            </Button>
            <Button
              onClick={handleNextPuzzle}
              disabled={isNextDisabled}
              sx={{
                ...buttonStyle,
                border: `1px solid ${currentTheme.borderColor}`,
                flex: "1 1 45%",
                backgroundColor: isNextDisabled ? "#d3d3d3" : "#2196f3",
                "&:hover": {
                  backgroundColor: isNextDisabled ? "#d3d3d3" : "#1976d2",
                },
              }}
            >
              {/* Flex container to evenly space icons and text */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                {isNextPuzzleCompleted && (
                  <CheckCircleIcon sx={{ color: 'green' }} />
                )}
                #{nextPuzzleNumber}
                <ArrowForwardIcon />
              </Box>
            </Button>
          </Box>
        )}


        {/* More Puzzles Section */}
        <Typography sx={{ mt: 2, mb: 1, fontWeight: "bold", color: currentTheme.textColor }}>
          More Puzzles
        </Typography>

        {/* Navigation Buttons */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {puzzleType === 'daily' && (
            <>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/pack")}>
                <AppsIcon />
                Packs
              </Button>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/archive")}>
                <HistoryIcon />
                Archive
              </Button>
            </>
          )}
          {puzzleType === 'archive' && (
            <>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/daily")}>
                <TodayIcon />
                Daily
              </Button>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/pack")}>
                <AppsIcon />
                Packs
              </Button>
            </>
          )}
          {puzzleType === 'pack' && (
            <>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/archive")}>
                <HistoryIcon />
                Archive
              </Button>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/daily")}>
                <TodayIcon />
                Daily
              </Button>
            </>
          )}
        </Box>

        {/* External Link */}
        {isNotInIframe && (
          <>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#FFD700",
            color: "black",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            mt: 2,
            width: '100%',
            "&:hover": {
              backgroundColor: "#FFC000",
            },
          }}
          href={buyMeCoffeeUrl}
          target="_blank"
        >
          <LocalCafeIcon sx={{ mr: 1 }} />
          Buy Me a Coffee
        </Button>
        <Typography sx={{ mt: 2, mb: 1, fontWeight: "bold", color: currentTheme.textColor }}>
          Get the App
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 2, gap: "10px" }}>
        <a href="https://apps.apple.com/us/app/syllacrostic/id6449267889" target="_blank" rel="noopener noreferrer" style={{ flex: '0 0 40%' }}>
          <img
            src={`${process.env.PUBLIC_URL}/appstore.png`}
            alt="App Store Logo"
            style={{ width: '100%', height: 'auto', filter: currentTheme.logoInvertColor }}
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.syllacrostic.app&pli=1" target="_blank" rel="noopener noreferrer" style={{ flex: '0 0 40%' }}>
          <img
            src={`${process.env.PUBLIC_URL}/googleplay.png`}
            alt="Google Play Logo"
            style={{ width: '100%', height: 'auto', filter: currentTheme.logoInvertColor }}
          />
        </a>
      </Box>
        </>
        )}
      </Box>
    </Modal>
  );
};

export default GameWonModal;
