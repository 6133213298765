import React, { createContext, useState, useEffect } from 'react';

export const IframeContext = createContext();

export const IframeProvider = ({ children }) => {
  const [isNotInIframe, setIsNotInIframe] = useState(false);

  useEffect(() => {
    const checkNotInIframe = window.self === window.top; // Check if not in an iframe
    setIsNotInIframe(checkNotInIframe);
  }, []);

  return (
    <IframeContext.Provider value={{ isNotInIframe }}>
      {children}
    </IframeContext.Provider>
  );
};
