// src/pages/LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Box, Button, TextField } from '@mui/material';
import axios from 'axios';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Note the updated URL to include /auth/login
      const response = await axios.post('/api/auth/login', {
        username,
        password,
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      
      if (response.data.access_token) {
        auth.login(response.data.access_token); 
        navigate('/admin'); 
      } else {
        alert('Invalid credentials!');
      }
    } catch (error) {
      console.error('Login failed', error);
      alert('Failed to log in. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <img src={`${process.env.PUBLIC_URL}/logo512x512.png`} alt="Logo" style={{ height: 60}} />
    </Box>
      <h1>Admin Login</h1>
      <TextField
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        sx={{ mb: 1 }}
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ mb: 1 }}
      />
      <Button variant="contained" color="primary" onClick={handleLogin}>
        Login
      </Button>
    </Box>
  );
};

export default LoginPage;
