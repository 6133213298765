import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
  const auth = useAuth(); // Use the authentication context

  // If the user is not logged in (no token), navigate to login page
  if (!auth.token) {
    return <Navigate to="/login" />;
  }

  // If the user is authenticated, render the child components (e.g., AdminPage)
  return children;
};

export default ProtectedRoute;
