// src/hooks/usePuzzleData.js

import { useState, useEffect } from "react";
import axios from "axios";

const usePuzzleData = (archivePuzzleNo, packPuzzleNo) => {
  const [puzzleData, setPuzzleData] = useState(null);
  const [puzzleType, setPuzzleType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [availableSyllables, setAvailableSyllables] = useState([]);
  const [clueStates, setClueStates] = useState([]);

  // Function to get the correct puzzle endpoint based on the input parameters
  const getPuzzleEndpoint = () => {
    if (packPuzzleNo) {
      setPuzzleType("pack");
      return `/api/puzzles/pack-puzzle-number/${packPuzzleNo}`;
    } else if (archivePuzzleNo) {
      setPuzzleType("archive");
      return `/api/puzzles/daily-puzzle-number/${archivePuzzleNo}`;
    } else {
      setPuzzleType("daily");
      return `/api/puzzles/daily-puzzle`;
    }
  };

  // Fetch puzzle data from the API
  const fetchPuzzleData = async () => {
    try {
      setLoading(true);
      setError(null);
      const endpoint = getPuzzleEndpoint();
      const response = await axios.get(endpoint);
      setPuzzleData(response.data);

      // Initialize available syllables and clue states
      const allSyllables = response.data.clues
        .flatMap((clue) => clue.syllables)
        .sort();

      const syllableObjects = allSyllables.map((syllable) => ({
        text: syllable,
        disabled: false,
      }));

      setAvailableSyllables(syllableObjects);

      const initialClueStates = response.data.clues.map((clue) => ({
        ...clue,
        filledSyllables: Array(clue.syllable_count).fill(""),
        isSolved: false,
        shake: false,
      }));

      setClueStates(initialClueStates);

    } catch (err) {
      console.error("Failed to fetch puzzle data:", err);
      setError("Failed to load puzzle. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPuzzleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archivePuzzleNo, packPuzzleNo]);

  return {
    puzzleData,
    puzzleType,
    loading,
    error,
    availableSyllables,
    clueStates,
    setClueStates,
    setAvailableSyllables,
    fetchPuzzleData, // Include fetchPuzzleData in the return statement
  };
};

export default usePuzzleData;
