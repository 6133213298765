// src/components/styles/cluesContainerStyles.js

import { styled, keyframes } from "@mui/material/styles";
import { Button } from "@mui/material";

// Define animations
export const pulse = keyframes`
  0% { transform: scale(1); box-shadow: 0 0 0px rgba(0, 0, 0, 0.3); }
  50% { transform: scale(1.05); box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }
  100% { transform: scale(1); box-shadow: 0 0 0px rgba(0, 0, 0, 0.3); }
`;

export const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

export const shake = keyframes`
  0% { transform: translateX(0); }
  20% { transform: translateX(-5px); }
  40% { transform: translateX(5px); }
  60% { transform: translateX(-5px); }
  80% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

// Styled Start Button
export const StartButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#28a745",
  color: "#fff",
  fontSize: "1.2rem",
  padding: "12px 24px",
  borderRadius: "30px",
  textTransform: "none",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  animation: `${pulse} 2s infinite`,
  "&:hover": {
    backgroundColor: "#218838",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.5)",
  },
}));
