import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useThemeContext } from '../contexts/ThemeContext';

const HowToPlay = () => {
  const { currentTheme } = useThemeContext();

  // Example syllables and clue data
  const syllables = ['AP', 'PLE'];
  const [filledSyllables, setFilledSyllables] = useState(Array(2).fill('')); // Array for storing selected syllables
  const [currentStep, setCurrentStep] = useState(0); // To track the current syllable being filled
  const [isCompleted, setIsCompleted] = useState(false); // Track if all syllables are filled

  // Automate filling the syllables
  useEffect(() => {
    if (currentStep < syllables.length) {
      const timer = setTimeout(() => {
        const newFilledSyllables = [...filledSyllables];
        newFilledSyllables[currentStep] = syllables[currentStep];
        setFilledSyllables(newFilledSyllables);
        setCurrentStep(currentStep + 1);
      }, 1000); // Set a delay of 1 second for each syllable

      return () => clearTimeout(timer); // Clear the timeout to avoid memory leaks
    } else if (currentStep === syllables.length) {
      setIsCompleted(true); // Mark as completed when all syllables are filled

      // Restart the loop after a delay
      const restartTimer = setTimeout(() => {
        setFilledSyllables(Array(2).fill('')); // Reset syllables
        setCurrentStep(0); // Reset the step
        setIsCompleted(false); // Reset completion
      }, 2000); // Wait 2 seconds before restarting

      return () => clearTimeout(restartTimer); // Clean up restart timer
    }
  }, [currentStep, syllables, filledSyllables]);

  const buttonBaseStyle = {
    color: "#ffffff",
    padding: "0.1em 0.3em",
    fontSize: "0.8em",
    fontWeight: "bold",
    transition: "all 0.3s ease",
    margin: "0.05rem",
    borderRadius: "5px",
    width: '60px',
    backgroundColor: "#3399FF",
    border: `2px solid ${currentTheme.borderColor}`,
    "&:disabled": {
      backgroundColor: currentTheme.buttonDisabledBackground,
      color: currentTheme.buttonDisabled,
    }
  };

  // Fallback to display an empty placeholder when syllable is empty or null
  const getSyllableDisplay = (syllable) => (syllable === "" || !syllable ? " " : syllable);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%', // Ensure it's centered vertically
      }}
    >
      <Typography variant="body1" sx={{ color: currentTheme.textColor, marginBottom: '10px' }}>
        Combine syllables to solve the clues!
      </Typography>

      {/* Example Clue */}
      <Typography variant="body2" sx={{ color: currentTheme.textColor, marginBottom: '5px' }}>
        Clue: "A red fruit"
      </Typography>

      {/* Clue Answer Area (Syllable spaces or Completed Answer with Checkmark) */}
      {isCompleted ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#28a745",
            animation: "fadeIn 0.5s ease-in-out",
            minHeight: "30px",
          }}
        >
          <Typography variant="subtitle1" sx={{ marginRight: "5px", fontWeight: "bold" }}>
            APPLE
          </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            sx={{
              fontWeight: "bold",
              fontSize: "0.8em"
            }}
          >
            ✅
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Center the clue spaces
            gap: "10px", // Adjust gap between syllable spaces
            marginTop: "3px",
          }}
        >
          {filledSyllables.map((syllable, idx) => (
            <Box
              key={idx}
              sx={{
                width: "50px",
                minWidth: "50px",
                maxWidth: "50px",
                height: "25px",
                color: currentTheme.textColor, // Ensure text color is white
                borderBottom: `2px solid ${currentTheme.textColor}`, // Always have the underline
                textAlign: "center", // Center text
                lineHeight: "25px", // Vertically center text
              }}
            >
              {getSyllableDisplay(syllable) || '______'}
            </Box>
          ))}
        </Box>
      )}

      {/* Syllable Buttons (These buttons are automated now and don't need clicks) */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        {syllables.map((syllable, idx) => (
          <Button
            key={syllable}
            disabled={filledSyllables[idx] !== ''} // Disable the button once it fills the space
            sx={buttonBaseStyle}
          >
            {syllable}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default HowToPlay;
