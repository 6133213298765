// src/utils/utils.js
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

// Function to capitalize the first letter of a string
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getPackGradient(packNumber) {
  return `linear-gradient(135deg, hsl(${packNumber * 40}, 70%, 60%), hsl(${
    packNumber * 40 + 30
  }, 70%, 60%))`;
}

// Function to format time as mm:ss
export function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const roundedSeconds = Math.round(remainingSeconds);
  return `${String(minutes).padStart(2, '0')}:${String(roundedSeconds).padStart(2, '0')}`;
}

// Arizona time is currently UTC-7
export function calculateCountdown() {
  const now = dayjs().tz("America/Phoenix");
  const nextMidnight = dayjs().tz("America/Phoenix").endOf('day');
  const diff = nextMidnight.diff(now, 'second');
  
  const hours = Math.floor(diff / 3600).toString().padStart(2, '0');
  const minutes = Math.floor((diff % 3600) / 60).toString().padStart(2, '0');
  const seconds = (diff % 60).toString().padStart(2, '0');
  
  return `${hours}:${minutes}:${seconds}`;
};

// Utility function to generate a random UUID
export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// Get the UUID if it exists, else set to NoId
export function getOrCreateAnonymousUserId() {
  let userId;
  try {
    userId = localStorage.getItem('anonymousUserId');
    if (!userId) {
      userId = generateUUID(); 
      localStorage.setItem('anonymousUserId', userId);
    }
  } catch (e) {
    userId = "NoUserId";
  }
  return userId;
}

// Function to get the parent URL
export function getReferrer() {
  return window.location.href;
}

// Function to get browser type
export function getBrowserType() {
  return navigator.userAgent;
}

// Function to handle puzzle start and send data to the backend
export async function handleStorePuzzleStart(puzzleType = 'Unknown', puzzleNumber = 0, isDarkMode = false, isSoundOn = true) {
  const userId = getOrCreateAnonymousUserId();
  const referrer = getReferrer() || 'NoReferrer';
  const screenWidth = window.innerWidth || 0;
  const screenHeight = window.innerHeight || 0;
  const browserType = getBrowserType() || 'UnknownBrowser';

  // Construct the payload to send to the backend
  const payload = {
    user_id: userId,
    referrer: referrer,
    puzzle_type: puzzleType,
    puzzle_number: puzzleNumber,
    screen_width: screenWidth,
    screen_height: screenHeight,
    browser_type: browserType,
    is_dark_mode: isDarkMode,
    is_sound_on: isSoundOn,
  };

  try {
    // Send the data to the backend using a POST request
    const response = await fetch('/api/puzzle-store/puzzle-start', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const result = await response.json();
    console.log('Puzzle start data stored successfully:', result);
  } catch (error) {
    console.error('Failed to store puzzle start data:', error);
  }
}

// Function to handle storing the completed puzzle data 
export async function handleStoreCompletedPuzzle(puzzleType = 'Unknown', puzzleNumber = 0, timeInSeconds = 0, isDarkMode = false, isSoundOn = true) {
  const userId = getOrCreateAnonymousUserId() || 'NoUserId';
  const referrer = getReferrer() || 'NoReferrer';
  const screenWidth = window.innerWidth || 0;
  const screenHeight = window.innerHeight || 0;
  const browserType = getBrowserType() || 'UnknownBrowser';

  // Construct the payload to send to the backend
  const payload = {
    user_id: userId,
    referrer: referrer,
    puzzle_type: puzzleType,
    puzzle_number: puzzleNumber,
    time_in_seconds: timeInSeconds,
    screen_width: screenWidth,
    screen_height: screenHeight,
    browser_type: browserType,
    is_dark_mode: isDarkMode,
    is_sound_on: isSoundOn,
  };

  try {
    // Send the data to the backend using a POST request
    const response = await fetch('/api/puzzle-store/puzzle-completed', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const result = await response.json();
    console.log('Puzzle completed data stored successfully:', result);
  } catch (error) {
    console.error('Failed to store puzzle completed data:', error);
  }
}
