// src/hooks/useSyllableHandlers.js

import { useCallback, useRef } from "react";
import { useSound } from '../hooks/useSound';

const useSyllableHandlers = (
  setClueStates,
  setAvailableSyllables,
  setSelectedSyllable,
  availableSyllables,
  selectedSyllable,
  isSoundOn
) => {

  const playSound = useSound();


  // Function to handle syllable clicks
  const handleSyllableClick = useCallback(
    (syllableText) => {
      if (!selectedSyllable) return;

      if (isSoundOn) playSound("click");

      const [clueIndex, syllableIndex] = selectedSyllable.split("-").map(Number);

      setClueStates((prevClueStates) => {
        const updatedClues = [...prevClueStates];
        const clue = { ...updatedClues[clueIndex] };
        const prevSyllable = clue.filledSyllables[syllableIndex];

        // Reactivate the previous syllable if any
        if (prevSyllable) {
          setAvailableSyllables((prevSyllables) =>
            prevSyllables.map((syllable) =>
              syllable.text === prevSyllable ? { ...syllable, disabled: false } : syllable
            )
          );
        }

        // Fill the syllable into the selected syllable space
        clue.filledSyllables = [...clue.filledSyllables];
        clue.filledSyllables[syllableIndex] = syllableText;

        // Deactivate syllable in control box
        setAvailableSyllables((prevSyllables) =>
          prevSyllables.map((syllable) =>
            syllable.text === syllableText ? { ...syllable, disabled: true } : syllable
          )
        );

        // Update the clue in the array
        updatedClues[clueIndex] = clue;

        // Check if all syllable spaces are filled
        if (!clue.filledSyllables.includes("")) {
          // Check if the filled syllables match the answer
          const filledAnswer = clue.filledSyllables.join("");
          if (filledAnswer.toLowerCase() === clue.answer.toLowerCase()) {
            // Correct Answer Sound

            if (isSoundOn) playSound("correct");

            // Correct answer
            clue.isSolved = true;

            // Remove used syllables from available syllables
            setAvailableSyllables((prevSyllables) =>
              prevSyllables.filter((syllable) => !clue.filledSyllables.includes(syllable.text))
            );
          } else {
            // Incorrect answer

            // Incorrect Sounds

            if (isSoundOn) playSound("mistake");

            // Apply shaking animation
            clue.shake = true;
            setTimeout(() => {
              setClueStates((prevClues) => {
                const cluesCopy = [...prevClues];
                const clueCopy = { ...cluesCopy[clueIndex] };
                clueCopy.shake = false;
                cluesCopy[clueIndex] = clueCopy;
                return cluesCopy;
              });
            }, 500); // Duration of shake animation

            // Store used syllables before clearing
            const usedSyllables = clue.filledSyllables.filter((s) => s !== "");

            // Clear filled syllables
            clue.filledSyllables = Array(clue.syllable_count).fill("");

            // Reactivate syllables
            setAvailableSyllables((prevSyllables) =>
              prevSyllables.map((syllable) =>
                usedSyllables.includes(syllable.text) ? { ...syllable, disabled: false } : syllable
              )
            );

            // Set selected syllable back to the first space of the clue
            setSelectedSyllable(`${clueIndex}-0`);
          }
        }

        // Update the clue in the array
        updatedClues[clueIndex] = clue;

        return updatedClues;
      });

      // Advance to next available syllable space in the same clue
      advanceSelectedSyllable(clueIndex, syllableIndex);
    },
    [selectedSyllable, setClueStates, setAvailableSyllables, setSelectedSyllable]
  );

  // Function to advance the selected syllable to the next empty space
  const advanceSelectedSyllable = useCallback(
    (currentClueIndex, currentSyllableIndex) => {
      setClueStates((prevClueStates) => {
        const clue = prevClueStates[currentClueIndex];
        let nextIndex = null;
        for (let i = currentSyllableIndex + 1; i < clue.filledSyllables.length; i++) {
          if (clue.filledSyllables[i] === "") {
            nextIndex = i;
            break;
          }
        }
        if (nextIndex !== null) {
          setSelectedSyllable(`${currentClueIndex}-${nextIndex}`);
        } else {
          // If no empty space in the current clue, move to the next clue
          let found = false;
          for (let i = currentClueIndex + 1; i < prevClueStates.length; i++) {
            const nextClue = prevClueStates[i];
            if (nextClue.isSolved) continue;
            const emptyIndex = nextClue.filledSyllables.findIndex((s) => s === "");
            if (emptyIndex !== -1) {
              setSelectedSyllable(`${i}-${emptyIndex}`);
              found = true;
              break;
            }
          }
          if (!found) {
            // Wrap around to the beginning
            for (let i = 0; i <= currentClueIndex; i++) {
              const nextClue = prevClueStates[i];
              if (nextClue.isSolved) continue;
              const emptyIndex = nextClue.filledSyllables.findIndex((s) => s === "");
              if (emptyIndex !== -1) {
                setSelectedSyllable(`${i}-${emptyIndex}`);
                found = true;
                break;
              }
            }
            if (!found) {
              // No empty spaces left
              setSelectedSyllable(null);
            }
          }
        }
        return prevClueStates;
      });
    },
    [setClueStates, setSelectedSyllable]
  );

  return {
    handleSyllableClick,
    advanceSelectedSyllable,
  };
};

export default useSyllableHandlers;
