import { useRef, useEffect } from 'react';
import clickSoundFile from '../sounds/click.wav'; 
import correctSoundFile from '../sounds/correct.wav'; 
import mistakeSoundFile from '../sounds/mistake.wav'; 
import puzzleSolvedSoundFile from '../sounds/puzzle_solved.wav'; 
import startPuzzleSoundFile from '../sounds/start_puzzle.wav'; 
import tickSoundFile from '../sounds/tick.wav';
import toggleSoundFile from '../sounds/toggle.wav';

export const useSound = () => {
  // Audio instances loaded once
  const sounds = useRef({});

  // Load audio files once when the component mounts
  useEffect(() => {
    sounds.current = {
      click: new Audio(clickSoundFile),
      correct: new Audio(correctSoundFile),
      mistake: new Audio(mistakeSoundFile),
      puzzle_solved: new Audio(puzzleSolvedSoundFile),
      start_puzzle: new Audio(startPuzzleSoundFile),
      tick: new Audio(tickSoundFile),
      toggle: new Audio(toggleSoundFile),
    };

    // Clean up audio instances when the component unmounts to avoid memory leaks
    return () => {
      Object.values(sounds.current).forEach(sound => {
        sound.pause();
        sound.src = ''; // Release audio file resources
        sound.removeAttribute('src');
      });
    };
  }, []);

  // Function to play a sound based on the passed key
  const playSound = (soundKey) => {
    const sound = sounds.current[soundKey];

    if (sound) {
      sound.pause(); // Stop the sound if it's already playing
      sound.currentTime = 0; // Reset the playback position to the start
      sound.play().catch((error) => {
        console.error(`Error playing ${soundKey} sound:`, error);
      });
    } else {
      console.error(`Sound ${soundKey} not found!`);
    }
  };

  return playSound; // Return the function to play a sound by key
};
