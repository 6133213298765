import React from 'react';
import { Box } from '@mui/material';
import PuzzleLayout from '../components/PuzzleLayout';
import { useThemeContext } from '../contexts/ThemeContext';
import { useMediaContext } from '../contexts/MediaContext';

const DailyPage = () => {
  const { isSoundOn, currentTheme } = useThemeContext();
  const { isMobile } = useMediaContext(); 

  return (
    <PuzzleLayout/>
  );
};

export default DailyPage;
