// src/components/Clue.js
import React from "react";
import { Box, Typography } from "@mui/material";
import ClueAnswer from "./ClueAnswer"; // Component for rendering clue answer
import { useThemeContext } from '../contexts/ThemeContext';

const Clue = ({ clue, index, selectedSyllable, setSelectedSyllable, fadeIn, shake }) => {
  const { currentTheme } = useThemeContext();
  return (
    <Box
      key={index}
      id={`clue-${index}`}
      sx={{
        marginBottom: "20px",
        animation: `${fadeIn} 0.3s ease-in-out`,
        position: "relative",
        minHeight: "60px",
      }}
    >
      {/* Clue text */}
      <Typography sx={{ color: currentTheme.textColor, fontWeight: "bold" }}>
        {index + 1}. {clue.clue}
      </Typography>

      {/* Render Answer Display using ClueAnswer component */}
      <ClueAnswer
        clue={clue}
        index={index}
        selectedSyllable={selectedSyllable}
        setSelectedSyllable={setSelectedSyllable}
        fadeIn={fadeIn}
        shake={shake}
      />
    </Box>
  );
};

export default Clue;
