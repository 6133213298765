// src/App.js

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import DailyPage from './pages/DailyPage';
import ArchivePage from './pages/ArchivePage';
import PackPage from './pages/PackPage';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { MediaProvider } from './contexts/MediaContext';
import { PuzzleDataProvider } from './contexts/PuzzleDataContext';
import { IframeProvider } from './contexts/IframeContext'; // Import IframeProvider
import ProtectedRoute from './components/ProtectedRoute';
import GlobalStyles from './styles/GlobalStyles'; // Import the GlobalStyles component
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'; // Import Privacy Policy page
import AppPrivacyPolicyPage from './pages/AppPrivacyPolicyPage'; // Import Privacy Policy page
import TermsOfServicePage from './pages/TermsOfServicePage'; // Import Terms of Service page
import BlogPage from './pages/BlogPage'; // Import Terms of Service page

function App() {

  useEffect(() => {
    // Ensure ArenaHelper is loaded on the window object
    if (window.ArenaHelper && typeof window.ArenaHelper.getInstance === "function") {
      const arenaHelper = window.ArenaHelper.getInstance();
  
      if (typeof arenaHelper.initTestingEnvironment === 'function') {
        // Initialize ArenaHelper when it's available
        arenaHelper.initTestingEnvironment();
        console.log("ArenaHelper initialized successfully");
  
        // Call handleGameStart right after initialization
        if (typeof arenaHelper.handleGameStart === 'function') {
          arenaHelper.handleGameStart();
          console.log("ArenaHelper game start triggered");
        } else {
          console.error("ArenaHelper's handleGameStart method is not available.");
        }
      } else {
        console.error("ArenaHelper's initTestingEnvironment method is not available.");
      }
    } else {
      console.error("ArenaHelper is not available on window.");
    }
  }, []);
  

  return (
    <AuthProvider>
      <ThemeProvider>
        <MediaProvider>
          <PuzzleDataProvider>
            <IframeProvider>
              <Router>
                <GlobalStyles />
                <Routes>
                  <Route path="/" element={<Navigate to="/daily" />} />
                  <Route path="/daily" element={<MainLayout><DailyPage /></MainLayout>} />
                  <Route path="/archive" element={<MainLayout><ArchivePage /></MainLayout>} />
                  <Route path="/pack" element={<MainLayout><PackPage /></MainLayout>} />
                  <Route path="/blog" element={<MainLayout><BlogPage /></MainLayout>} />
                  <Route path="/ios_app_privacy" element={<Navigate to="/privacy-policy-apps" />} />
                  <Route path="/privacy-policy" element={<MainLayout><PrivacyPolicyPage /></MainLayout>} />
                  <Route path="/privacy-policy-apps" element={<MainLayout><AppPrivacyPolicyPage /></MainLayout>} />
                  <Route path="/terms-of-service" element={<MainLayout><TermsOfServicePage /></MainLayout>} />

                  {/* Protected Routes */}
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/admin" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />

                </Routes>
              </Router>
            </IframeProvider>
          </PuzzleDataProvider>
        </MediaProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;