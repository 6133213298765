// src/pages/ArchivePage.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
  Button,
} from '@mui/material';
import PuzzleLayout from '../components/PuzzleLayout';
import PuzzleSelectionInterface from '../components/PuzzleSelectionInterface';
import { useThemeContext } from '../contexts/ThemeContext';
import axios from 'axios';
import { useMediaContext } from '../contexts/MediaContext';
import { useLocation } from 'react-router-dom';
import { PuzzleDataContext } from '../contexts/PuzzleDataContext';
import { formatTime } from "../utils/utilityFunctions";
import useDailyPuzzleNumber from '../hooks/useDailyPuzzleNumber';
import useArchivePuzzles from '../hooks/useArchivePuzzles';
import Loading from "../components/Loading";

const ArchivePage = () => {
  const { isSoundOn, currentTheme } = useThemeContext();
  const { isMobile } = useMediaContext();
  const { puzzleData } = useContext(PuzzleDataContext);
  const [selectedPuzzleNo, setSelectedPuzzleNo] = useState(null);
  const [showUnplayedOnly, setShowUnplayedOnly] = useState(false);
  const [sortOrder, setSortOrder] = useState('newest');
  const location = useLocation();

  // Use custom hooks to get daily puzzle data and process archive puzzles
  const { todayPuzzleNo, todayPuzzleDate, loading, error } = useDailyPuzzleNumber();
  const { puzzleList, stats } = useArchivePuzzles(todayPuzzleNo, todayPuzzleDate, puzzleData, sortOrder, showUnplayedOnly);

  useEffect(() => {
    if (location.state?.reset) {
      setSelectedPuzzleNo(null);
    }
  }, [location.state]);

  if (loading) {
    return (
      <Loading message={" Archive"} />
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        color: currentTheme.textColor,
        height: '100%',
        maxWidth: '550px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        minHeight: 0,
        margin: '0 auto',
        scrollbarWidth: 'thin',
        scrollbarColor: `${currentTheme.borderColor} transparent`,
      }}
    >
      {!selectedPuzzleNo ? (
        // Pass setSelectedPuzzleNo as a prop to PuzzleSelectionInterface
        <PuzzleSelectionInterface
          stats={stats}
          puzzleList={puzzleList}
          setSelectedPuzzleNo={setSelectedPuzzleNo}
          puzzleType={'archive'}
        />   
      ) : (
        <PuzzleLayout archivePuzzleNo={selectedPuzzleNo} setSelectedPuzzleNo={setSelectedPuzzleNo} />
      )}
    </Box>
  );
};

export default ArchivePage;
