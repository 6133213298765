import React from "react";
import { Box, Typography } from "@mui/material";
import { useThemeContext } from '../contexts/ThemeContext';

// Define CSS Keyframes as an object for MUI `sx` prop usage
const animations = {
  colorTransition: {
    "@keyframes colorTransition": {
      "0%": { filter: "hue-rotate(0deg)" },
      "100%": { filter: "hue-rotate(360deg)" },
    },
    animation: "colorTransition 2s infinite alternate",
  },
  pulse: {
    "@keyframes pulse": {
      "0%": { opacity: 1 },
      "50%": { opacity: 0.5 },
      "100%": { opacity: 1 },
    },
    animation: "pulse 2s infinite ease-in-out",
  },
};

const Loading = ({ message = null }) => {
  const { currentTheme } = useThemeContext();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
        backgroundColor: currentTheme.primaryColor, // Use theme background color
        overflow: "hidden",
      }}
    >
      {/* Logo Image with Animation */}
      <Box
        component="img"
        src={`${process.env.PUBLIC_URL}/logo512x512.png`} // Replace with your actual logo path
        alt="Loading Logo"
        style={{ filter: currentTheme.logoInvertColor }} 
        sx={{
          width: 150,
          height: 150,
          objectFit: "contain",
          ...animations.pulse, // Apply color transition animation
          marginBottom: "1px",
        }}
      />

      {/* Loading Text with Animation */}
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          color: currentTheme.textColor, // Use primary color for text
          marginTop: "1px",
          ...animations.pulse, // Apply pulse animation
        }}
      >
        Loading{message}...
      </Typography>
    </Box>
  );
};

export default Loading;
