import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  Paper,
  Button,
} from '@mui/material';
import PuzzleLayout from '../components/PuzzleLayout';
import { useThemeContext } from '../contexts/ThemeContext';
import { useMediaContext } from '../contexts/MediaContext';
import { formatTime, capitalizeFirstLetter } from "../utils/utilityFunctions";
import Loading from "../components/Loading";
import { useSound } from '../hooks/useSound';

const PuzzleSelectionInterface = ({ stats, puzzleList, setSelectedPuzzleNo, puzzleType, packNumber, backgroundGradient=null }) => {
  const { isSoundOn, currentTheme } = useThemeContext();
  const { isMobile } = useMediaContext();
  const [showUnplayedOnly, setShowUnplayedOnly] = useState(false);

  const playSound = useSound();

  // Filter puzzles based on showUnplayedOnly value
  const filteredPuzzles = showUnplayedOnly
    ? puzzleList.filter(puzzle => !puzzle.played)
    : puzzleList;

  // Check if stats or puzzleList are undefined or null
  if (!stats || !puzzleList) {
    return <Loading message={` ${capitalizeFirstLetter(puzzleType)}`} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flex: 1,
        padding: '10px',
        minHeight: 0,
      }}
    >
      {/* Stats and Toggles */}
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          maxWidth: '550px',
          padding: '20px',
          background: backgroundGradient,
          backgroundColor: currentTheme.secondaryColor,
          color: currentTheme.textColor,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
          {puzzleType.toUpperCase()} {packNumber ? `#${packNumber}` : ''}
        </Typography>

        {/* Stats */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: isMobile ? '100%' : '500px',
            marginBottom: '5px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '50%',
              borderRight: `1px solid ${currentTheme.borderColor}`,
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>TOTAL PLAYED</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {stats.completed}/{stats.total}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '50%',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>AVERAGE TIME</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {stats.averageTime !== '--:--' ? `${formatTime(stats.averageTime)}` : '--:--'}
            </Typography>
          </Box>
        </Box>

        {/* Toggles for filtering and sorting */}
        {puzzleType === 'archive' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={showUnplayedOnly}
                  onChange={() => {
                    if (isSoundOn) playSound("toggle"); 
                    setShowUnplayedOnly(!showUnplayedOnly);
                  }}
                  color="primary"
                />
              }
              label="Show Unplayed Only"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            />
          </Box>
        )}
      </Paper>

      {/* Puzzle List */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
          width: '100%',
          minHeight: 0,
          scrollbarWidth: "thin", // Use 'thin' keyword for a thinner scrollbar
          scrollbarColor: `${currentTheme.borderColor} transparent`, // Thumb and track colors
        }}
      >
        {filteredPuzzles.length > 0 ? (
          filteredPuzzles.map((puzzle) => (
            <Button
              key={puzzle.puzzleNo}
              variant="contained"
              onClick={() => {
                if (isSoundOn) playSound("toggle"); 
                setSelectedPuzzleNo(puzzle.puzzleNo);
              }}
              sx={{
                margin: '5px 0',
                width: '95%',
                maxWidth: '550px',
                borderRadius: '8px',
                backgroundColor: currentTheme.secondaryColor,
                color: currentTheme.textColor,
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 20px',
              }}
            >
              {/* Left Section: Puzzle Number and Date */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  #{puzzle.puzzleNo}
                </Typography>
                {puzzleType === 'archive' && (
                    <Typography
                    variant="body2"
                    sx={{ fontSize: '14px', color: currentTheme.textColor }}
                    >
                    {puzzle.puzzleDate}
                    </Typography>
                )}
              </Box>

              {/* Right Section: Completion Time or UNPLAYED */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '4px 8px',
                  backgroundColor: puzzle.played ? '#2e8c2e' : 'red',
                  borderRadius: '4px',
                  width: '80px',
                  border: currentTheme.timerBorder, 
                }}
              >
                {puzzle.played ? (
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'bold',
                      color: '#fff',
                    }}
                  >
                    {formatTime(puzzle.completionTime)}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'bold',
                      color: 'white',
                    }}
                  >
                    UNPLAYED
                  </Typography>
                )}
              </Box>
            </Button>
          ))
        ) : (
          <Typography>No puzzles available.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default PuzzleSelectionInterface;
