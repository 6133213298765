// src/components/HeaderTitle.js

import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { useThemeContext } from '../contexts/ThemeContext';
import { IframeContext } from '../contexts/IframeContext'; 

const HeaderTitle = () => {
  const { currentTheme } = useThemeContext();
  const { isNotInIframe } = useContext(IframeContext); 

  return (
    <Box
      sx={{
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{
          fontFamily: 'Arial, sans-serif',
          fontWeight: 'bold',
          fontSize: '1.2em',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* SYL */}
        <Box
          sx={{
            borderBottom: `2px solid ${currentTheme.textColor}`,
            mr: 1,
            fontWeight: 'bold',
            lineHeight: 1,
            paddingBottom: '2px',
          }}
        >
          SYL
        </Box>

        {/* LA */}
        <Box
          sx={{
            borderBottom: `2px solid ${currentTheme.textColor}`,
            mr: 1,
            fontWeight: 'bold',
            lineHeight: 1,
            paddingBottom: '2px',
          }}
        >
          LA
        </Box>

        {/* CROS */}
        <Box
          sx={{
            borderBottom: `2px solid ${currentTheme.textColor}`,
            mr: 1,
            fontWeight: 'bold',
            lineHeight: 1,
            paddingBottom: '2px',
          }}
        >
          CROS
        </Box>

        {/* TIC with conditional trademark */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            lineHeight: 1,
            paddingBottom: '2px',
          }}
        >
          <Box
            sx={{
              borderBottom: `2px solid ${currentTheme.textColor}`,
            }}
          >
            TIC
          </Box>
          {isNotInIframe && (
            <Box
              component="span"
              sx={{ ml: 0.5, fontSize: '0.7em', lineHeight: 1, position: 'relative', top: '-4px' }}
            >
              &reg;
            </Box>
          )}
        </Box>
      </Typography>
    </Box>
  );
};

export default HeaderTitle;
