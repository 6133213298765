import React, { useEffect, useRef, useContext } from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; 
import { StartButton, fadeIn, shake } from "../styles/cluesContainerStyles";
import { capitalizeFirstLetter } from "../utils/utilityFunctions";
import { useThemeContext } from '../contexts/ThemeContext';
import Clue from "./Clue";
import { PuzzleDataContext } from '../contexts/PuzzleDataContext';

const CluesContainer = ({ clues, puzzleType, puzzleNumber, gameStarted, timer, selectedSyllable, setSelectedSyllable, onStart }) => {
  const { currentTheme } = useThemeContext();
  const containerRef = useRef(null);
  const { puzzleData } = useContext(PuzzleDataContext); 
  const isPuzzleCompleted = puzzleData.some(
    (entry) => entry.puzzleType === puzzleType && entry.puzzleNumber === puzzleNumber
  );

  // Auto-scroll to the selected clue within the container
  useEffect(() => {
    if (selectedSyllable && containerRef.current) {
      const [clueIndex] = selectedSyllable.split("-").map(Number);
      const clueElement = containerRef.current.querySelector(`#clue-${clueIndex}`);
      if (clueElement) {
          // Mobile: Scroll to bring the selected clue as close to the top as possible
          const containerTop = containerRef.current.getBoundingClientRect().top;
          const clueTop = clueElement.getBoundingClientRect().top;
  
          // If it's the first selected clue, scroll exactly to the top
          if (clueIndex === 0) {
            containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
          } else {
            const scrollOffset = clueTop - containerTop;
            containerRef.current.scrollBy({ top: scrollOffset, behavior: "smooth" });
          }
      }
    }
  }, [selectedSyllable]);
  

  return (
    <Box sx={{
      display: "flex", 
      flexDirection: "column", 
      padding: "10px", 
      border: `1px solid ${currentTheme.borderColor}`, 
      backgroundColor: currentTheme.secondaryColor, 
      width: "300px", 
      borderRadius: "8px",
      maxHeight: "100vh", 
      flex: 1, 
      position: "relative" }}>
      {/* Static Top Area */}
      <Box sx={{ 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        width: "100%", 
        padding: "10px 0", 
        position: "sticky", 
        top: 0, 
        zIndex: 1, 
        backgroundColor: currentTheme.secondaryColor, 
        boxShadow: currentTheme.shadow, 
        marginBottom: "15px", 
        borderRadius: "8px" }}>


        {/* Puzzle Title and Checkmark */}
        <Typography
          sx={{
            color: currentTheme.textColor,
            fontWeight: "bold",
            fontSize: "1em",
            textAlign: "left",
            paddingLeft: "10px",
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          {puzzleType === 'pack' ? 'Pack Puzzle' : capitalizeFirstLetter(puzzleType)} #{puzzleNumber}
          {/* Render checkmark icon if puzzle is completed */}
          {isPuzzleCompleted && (
            <CheckCircleIcon
              sx={{
                color: "#4caf50", // Set checkmark color to green
                marginLeft: "8px", // Add some space between the text and the icon
                fontSize: "1.2em", // Adjust icon size
              }}
            />
          )}
        </Typography>

        {/* Timer Box Fixed to Right */}
        <Box sx={{ 
          position: "absolute", 
          right: "10px", 
          minWidth: "60px", 
          padding: "5px", 
          borderRadius: "4px", 
          backgroundColor: gameStarted ? "#28a745" : "#6c757d", 
          textAlign: "center", 
          color: "#ffffff", 
          border: currentTheme.timerBorder, 
          fontSize: "0.9em", 
          fontWeight: "bold", 
          transition: "background-color 0.3s ease" }}>
          {new Date(timer * 1000).toISOString().substr(14, 5)}
        </Box>
      </Box>

      {/* Content Area */}
      {!gameStarted ? (
        <Box sx={{ 
          flex: 1, 
          display: "flex", 
          justifyContent: "center", 
          alignItems: "center" }}>
          <StartButton onClick={onStart}>Start</StartButton>
        </Box>
      ) : (
      <Box
        ref={containerRef}
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          flex: 1,
          paddingLeft: "5px",
          scrollbarWidth: "thin", // Use 'thin' keyword for a thinner scrollbar
          scrollbarColor: `${currentTheme.borderColor} transparent`, // Thumb and track colors
        }}
      >
          {clues.map((clue, index) => (
            <Clue 
              key={index} 
              clue={clue} 
              index={index} 
              selectedSyllable={selectedSyllable} 
              setSelectedSyllable={setSelectedSyllable} 
              fadeIn={fadeIn} 
              shake={shake} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CluesContainer;
