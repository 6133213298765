// src/components/CustomModal.js

import React from 'react';
import {
  Box,
  IconButton,
  Typography,
  Modal,
  FormControlLabel,
  Switch,
  Fade,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom'; 
import { useThemeContext } from '../contexts/ThemeContext';
import { useSound } from '../hooks/useSound';
import HowToPlay from '../components/HowToPlay'; 

const CustomModal = ({ open, onClose, modalType }) => {
  const { currentTheme, isSoundOn, setIsDarkMode, setIsSoundOn, isDarkMode } = useThemeContext();
  const navigate = useNavigate();
  const playSound = useSound();

  const handleItemClick = (callback) => {
    if (isSoundOn) playSound("toggle"); // Play sound when any list item is clicked
    callback(); // Execute the passed navigation or modal action
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: currentTheme.secondaryColor,
    border: `2px solid ${currentTheme.borderColor}`,
    boxShadow: currentTheme.shadow,
    p: 4,
    borderRadius: '8px',
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Fade in={open}>
        <Box sx={modalStyle}>
          {/* Close Button */}
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 8, right: 8, color: currentTheme.textColor }}
          >
            <CloseIcon />
          </IconButton>

      {/* Modal Content based on modal type */}
      {modalType === 'Settings' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Center content horizontally
            justifyContent: 'center', // Center content vertically
            textAlign: 'center',
          }}
        >
          {/* Centered Header */}
          <Typography variant="h6" component="h2" gutterBottom sx={{ color: currentTheme.textColor }}>
            Settings
          </Typography>

          {/* Divider */}
          <Divider sx={{ borderColor: currentTheme.textColor, mt: 1, mb: 1, width: '100%' }} />

          {/* Dark Mode Switch */}
          <FormControlLabel
            control={
              <Switch
                checked={isDarkMode}
                onChange={(e) => {
                  if (isSoundOn) playSound("toggle");
                  setIsDarkMode(e.target.checked);
                }}
                color="primary"
              />
            }
            label="Dark Mode"
            sx={{
              color: currentTheme.textColor,
              width: '100%',
              justifyContent: 'center', // Center the switch and label
              textAlign: 'center', // Center the label text
            }}
          />

          {/* Sound Switch */}
          <FormControlLabel
            control={
              <Switch
                checked={isSoundOn}
                onChange={(e) => {
                  if (!isSoundOn) playSound("toggle");
                  setIsSoundOn(e.target.checked);
                }}
                color="primary"
              />
            }
            label="Sound"
            sx={{
              color: currentTheme.textColor,
              width: '100%',
              justifyContent: 'center', // Center the switch and label
              textAlign: 'center', // Center the label text
            }}
          />
        </Box>
      )}


          {modalType === 'HowToPlay' && (
            <>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" component="h2" gutterBottom sx={{ color: currentTheme.textColor }}>
                How To Play
              </Typography>
              <Divider sx={{ borderColor: currentTheme.textColor, mt:1, mb:1 }} />
              <HowToPlay />  {/* Render the imported HowToPlay component here */}
              </Box>
            </>
          )}

            {modalType === 'About' && (
            <>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" component="h2" gutterBottom sx={{ color: currentTheme.textColor }}>
                About
              </Typography>
              
              <Divider sx={{ borderColor: currentTheme.textColor, mt: 1, mb: 1 }} />
              
              {/* Developer Information */}
              <Typography variant="body1" sx={{ color: currentTheme.textColor, mb: 2 }}>
                Developed by Mike Pauley<br />
                Tucson, AZ<br />
                <a href="mailto:mike@pauleyco.com" style={{ color: currentTheme.textColor }}>
                  mike@pauleyco.com
                </a>
                <hr />
                Find my other games at:<br />
                <a href="http://pauleyco.com" target="_blank" style={{ color: currentTheme.textColor }}>
                  www.PauleyCo.com
                </a>
                <hr />
                Syllacrostic © 2024 PauleyCo, LLC<br />
                Version: 1.1.1<br />
                <hr />
              </Typography>
            </Box>
              {/* Privacy Policy and Terms of Service Links */}
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 1, mb: 1, gap: "10px" }}>
              <ListItem
                onClick={() => handleItemClick(() => {
                  navigate('/privacy-policy'); // Navigate to the Privacy Policy page
                  onClose();
                })}
              >
                <ListItemText
                  primary="Privacy Policy"
                  primaryTypographyProps={{
                    sx: { color: currentTheme.textColor, fontSize: '12px', whiteSpace: 'nowrap', textAlign: 'center' } // Apply font size and nowrap
                  }}
                />
              </ListItem>
              <ListItem
                onClick={() => handleItemClick(() => {
                  navigate('/terms-of-service'); // Navigate to the Terms of Service page
                  onClose();
                })}
              >
                <ListItemText
                  primary="Terms of Service"
                  primaryTypographyProps={{
                    sx: { color: currentTheme.textColor, fontSize: '12px', whiteSpace: 'nowrap', textAlign: 'center' } // Apply font size and nowrap
                  }}
                />
              </ListItem>
            </Box>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
