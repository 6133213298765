// src/components/PuzzleQC.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Divider } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const PuzzleQC = () => {
  const [clueData, setClueData] = useState(null);  // State to hold clue data
  const [altClue, setAltClue] = useState('');      // State to hold alternative clue input
  const [loading, setLoading] = useState(false);   // Loading state to prevent multiple submissions
  const auth = useAuth();                          // Get auth context
  const token = auth.token;                        // Retrieve token from context

  // Fetch the next clue to process when the component mounts
  useEffect(() => {
    const fetchNextClue = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/puzzle-qc/next-clue', {
          headers: {
            Authorization: `Bearer ${token}`,   // Include the JWT token for authentication
          },
        });
        setClueData(response.data);             // Set the clue data to state
      } catch (error) {
        console.error('Error fetching the next clue:', error);
        setClueData(null);                      // Reset clue data on error
      } finally {
        setLoading(false);
      }
    };

    fetchNextClue();
  }, [token]);  // Fetch clue data again if the token changes

  // Function to store the processed clue data
  const storeProcessedClue = async (qcFlag) => {
    if (!clueData) return;
    setLoading(true);  // Set loading state to true
    try {
      // Prepare the data to send to the backend
      const processedData = {
        id: clueData.id,
        clue: clueData.clue,
        alt_clue: altClue || null,
        answer: clueData.answer,
        syllable_count: clueData.syllable_count,
        syllables: clueData.syllables,
        qc_flag: qcFlag,  // Use the qcFlag passed to this function
        qc_datetime: new Date().toISOString(),
      };

      console.log(processedData)

      // Send the POST request to store the processed clue
      await axios.post('/api/puzzle-qc/store-processed-clue', processedData, {
        headers: {
          Authorization: `Bearer ${token}`,  // Include the JWT token for authentication
        },
      });

      // Reset the alternative clue input
      setAltClue('');

      // Fetch the next clue to process after storing the current one
      const nextClueResponse = await axios.get('/api/puzzle-qc/next-clue', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClueData(nextClueResponse.data);  // Update the UI with the next clue
    } catch (error) {
      console.error('Error storing the processed clue:', error);
    } finally {
      setLoading(false);  // Reset loading state
    }
  };

  return (
    <Box sx={{ padding: 2, maxWidth: "400px", justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
      {clueData ? (
        <>
          <Typography variant="h6" component="h4">
            Clue #{clueData.id}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography variant="h5" component="h5">
            {clueData.clue}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography variant="body1" component="p">
            Answer: {clueData.answer}
          </Typography>
          <Typography variant="body1" component="p">
            Syllables: {clueData.syllables} ({clueData.syllable_count})
          </Typography>
          <Divider sx={{ my: 1 }} />

          {/* Alternative Clue Input */}
          <TextField
            label="Alternative Clue"
            placeholder="Enter an alternative clue..."
            value={altClue}
            onChange={(e) => setAltClue(e.target.value)}
            fullWidth
            disabled={loading}  // No comment inside the attribute
          />
          <Divider sx={{ my: 1 }} />

          {/* Buttons Container */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
            <Button
              variant="contained"
              color="error"
              onClick={() => storeProcessedClue(null)}  // Store with no QC flag
              sx={{ width: '48%' }}
              disabled={loading}  // Disable button if loading
            >
              No
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => storeProcessedClue(1)}  // Store with QC flag as 1
              sx={{ width: '48%' }}
              disabled={loading}  // Disable button if loading
            >
              Yes
            </Button>
          </Box>
        </>
      ) : (
        <Typography variant="h6" component="h6">
          {loading ? 'Loading...' : 'No clue available to process.'}
        </Typography>
      )}
    </Box>
  );
};

export default PuzzleQC;
