// src/components/MainLayout.js
import React, { useState } from 'react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SidebarDrawer from './SidebarDrawer';
import CustomModal from './CustomModal';
import HeaderTitle from './HeaderTitle';
import { useThemeContext } from '../contexts/ThemeContext'; // Use useThemeContext
import { useSound } from '../hooks/useSound';

const MainLayout = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openModal, setOpenModal] = useState(null);

  // Access theme-related states from context
  const { isSoundOn, currentTheme } = useThemeContext();
  const playSound = useSound();

  const handleOpenDrawer = () => {
    if (isSoundOn) playSound("toggle"); 
    setIsDrawerOpen(true); // Open the drawer
  };

  const handleOpenHowToPlay = () => {
    if (isSoundOn) playSound("toggle"); 
    setOpenModal('HowToPlay');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: currentTheme.primaryColor, // Use currentTheme
      }}
    >
      {/* Header */}
      <Box
        sx={{
          height: '40px',
          backgroundColor: currentTheme.secondaryColor, // Use currentTheme
          color: currentTheme.textColor, // Use currentTheme
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Left Icons */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton sx={{ color: currentTheme.textColor }} onClick={handleOpenDrawer}>
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Header Title */}
        <HeaderTitle />

        {/* Right Icons */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          <IconButton sx={{ color: currentTheme.textColor }} onClick={handleOpenHowToPlay}>
            <HelpOutlineIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Sidebar Drawer */}
      <SidebarDrawer
        isDrawerOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        handleOpenModal={setOpenModal}
      />

      {/* Modals */}
      <CustomModal
        open={openModal === 'Settings'}
        onClose={() => setOpenModal(null)}
        modalType="Settings"
      />
      <CustomModal
        open={openModal === 'HowToPlay'}
        onClose={() => setOpenModal(null)}
        modalType="HowToPlay"
      />
      <CustomModal
        open={openModal === 'Statistics'}
        onClose={() => setOpenModal(null)}
        modalType="Statistics"
      />
      <CustomModal
        open={openModal === 'About'}
        onClose={() => setOpenModal(null)}
        modalType="About"
      />

      {/* Main Content */}
      <Box
        sx={{
          marginTop: '50px',
          height: 'calc(100vh - 50px)',
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
