// src/components/ClueAnswer.js
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useThemeContext } from '../contexts/ThemeContext';
import { useSound } from '../hooks/useSound';

const ClueAnswer = ({ clue, index, selectedSyllable, setSelectedSyllable, fadeIn, shake }) => {
  const { currentTheme, isSoundOn } = useThemeContext();
  // Fallback to display an empty placeholder when syllable is empty or null
  const getSyllableDisplay = (syllable) => (syllable === "" || !syllable ? " " : syllable);
  const playSound = useSound();

  return clue.isSolved ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        color: "#28a745",
        animation: `${fadeIn} 0.5s ease-in-out`,
        minHeight: "30px",
        paddingLeft: "19px",
      }}
    >
      <Typography variant="subtitle1" sx={{ marginRight: "5px", fontWeight: "bold" }}>
        {clue.answer.toUpperCase()}
      </Typography>
      <Typography
        variant="subtitle1"
        component="span"
        sx={{
        fontWeight: "bold",
        fontSize: "0.8em"
        }}
        >
         ✅
    </Typography>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        gap: "5px",
        marginTop: "3px",
        paddingLeft: "15px",
        flexWrap: "wrap",
        animation: clue.shake ? `${shake} 0.5s` : "none",
      }}
    >
      {clue.filledSyllables.map((syllable, idx) => (
        <Button
          key={idx}
          variant="text"
          onClick={() => {
            if (isSoundOn) playSound("tick"); 
            if (selectedSyllable !== `${index}-${idx}`) {
              setSelectedSyllable(`${index}-${idx}`);
            }
          }}
          sx={{
            width: "50px",
            minWidth: "50px",
            maxWidth: "50px",
            height: "25px",
            position: "relative",
            borderRadius: "8px",
            backgroundColor: selectedSyllable === `${index}-${idx}` ? `rgba(51, 153, 255, 0.5)` : "transparent",
            color: currentTheme.textColor,
            cursor: selectedSyllable === `${index}-${idx}` ? "default" : "pointer",
            transition: "all 0.05s ease-in-out",
            "&:hover": {
              backgroundColor: selectedSyllable === `${index}-${idx}` ? `rgba(51, 153, 255, 0.5)` : `rgba(0, 0, 0, 0.1)`,
            },
            "&:active": {
              backgroundColor: selectedSyllable === `${index}-${idx}` ? `rgba(51, 153, 255, 0.5)` : `rgba(0, 0, 0, 0.15)`,
            },
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-2px",
              left: 0,
              width: "100%",
              height: "2px",
              backgroundColor: currentTheme.textColor,
              borderRadius: "0px",
              transition: "transform 0.05s ease-in-out",
            },
          }}
        >
          {getSyllableDisplay(syllable)}
        </Button>
      ))}
    </Box>
  );
};

export default ClueAnswer;
