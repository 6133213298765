// src/components/ControlBox.js

import React from "react";
import { Box, Button, Divider } from "@mui/material";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useThemeContext } from '../contexts/ThemeContext';
import { useMediaContext } from '../contexts/MediaContext';

const buttonBaseStyle = {
  color: "#ffffff",
  padding: "0.1em 0.3em",
  fontSize: "0.8em",
  fontWeight: "bold",
  transition: "all 0.3s ease",
  margin: "0.05rem",
};

const ControlBox = ({
  syllables,
  onShuffle,
  onSort,
  onClear,
  gameStarted,
  handleSyllableButtonClick,
  gameWon,
  puzzleType,
  handlePreviousPuzzle, // Add prop for previous puzzle navigation
  handleNextPuzzle,     // Add prop for next puzzle navigation
  isPrevDisabled,       // Add prop for disabling the previous button
  isNextDisabled,       // Add prop for disabling the next button
  prevPuzzleNumber,     // Add prop for previous puzzle number
  nextPuzzleNumber,     // Add prop for next puzzle number
  isPrevPuzzleCompleted, // Add prop for indicating previous puzzle completion
  isNextPuzzleCompleted, // Add prop for indicating next puzzle completion
}) => {
  const { isSoundOn, currentTheme } = useThemeContext();
  const { isMobile } = useMediaContext();
  const buttonWidth = isMobile ? "60px" : "90px";

  const controlsDisabled = !gameStarted;

  const buttonNavStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    background: currentTheme.secondaryColor,
    color: currentTheme.textColor,
    width: '100%',
    mb: 1,
    padding: '10px 15px',
    borderRadius: '10px',
    fontWeight: "bold",
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
    transition: 'all 0.3s ease-in-out',
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.25)',
    },
  };

  const iconStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '5px', // Space between icons and text
  };

  if (gameWon) {
    // Render just the logo with navigation buttons if it is pack or archive
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          border: `1px solid ${currentTheme.borderColor}`,
          backgroundColor: currentTheme.secondaryColor,
          width: isMobile ? "350px" : "300px",
          borderRadius: "8px",
          maxHeight: "90%",
          overflowY: "hidden",
          flex: 1,
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/logo512x512.png`}
          alt="Logo"
          style={{ height: 60, filter: currentTheme.logoInvertColor }}
        />

        {puzzleType !== 'daily' && (
          <Box
          sx={{
            width: '90%',
            height: '2px',
            backgroundColor: currentTheme.textColor,
            marginBottom: "15px",
            marginTop: "15px"
          }}
        />

        )}
        
        {/* Conditionally Render Previous and Next Buttons */}
        {puzzleType !== 'daily' && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 2, gap: "5px" }}>
            <Button
              onClick={handlePreviousPuzzle}
              disabled={isPrevDisabled}
              sx={{
                ...buttonNavStyle,
                border: `1px solid ${currentTheme.borderColor}`,
                flex: "1 1 45%",
                backgroundColor: isPrevDisabled ? "#d3d3d3" : "#2196f3",
                "&:hover": {
                  backgroundColor: isPrevDisabled ? "#d3d3d3" : "#1976d2",
                },
              }}
            >
              <Box sx={iconStyle}>
                <ArrowBackIcon />
                #{prevPuzzleNumber}
                {isPrevPuzzleCompleted && (
                  <CheckCircleIcon sx={{ color: 'green' }} />
                )}
              </Box>
            </Button>
            <Button
              onClick={handleNextPuzzle}
              disabled={isNextDisabled}
              sx={{
                ...buttonNavStyle,
                border: `1px solid ${currentTheme.borderColor}`,
                flex: "1 1 45%",
                backgroundColor: isNextDisabled ? "#d3d3d3" : "#2196f3",
                "&:hover": {
                  backgroundColor: isNextDisabled ? "#d3d3d3" : "#1976d2",
                },
              }}
            >
              <Box sx={iconStyle}>
                {isNextPuzzleCompleted && (
                  <CheckCircleIcon sx={{ color: 'green' }} />
                )}
                #{nextPuzzleNumber}
                <ArrowForwardIcon />
              </Box>
            </Button>
          </Box>
        )}
      </Box>
    );
  } else {
    // Render the original ControlBox content
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          border: `1px solid ${currentTheme.borderColor}`,
          backgroundColor: currentTheme.secondaryColor,
          width: isMobile ? "350px" : "300px",
          borderRadius: "8px",
          maxHeight: "90%",
          overflowY: "hidden",
          flex: 1,
          alignItems: "center",
        }}
      >
        {/* Syllable Buttons Container */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center", // Center items horizontally
            gap: isMobile ? "0.1rem" : "0.05rem",
            width: "100%",
            maxWidth: isMobile ? "100%" : "280px",
          }}
        >
          {syllables.map((syllable, index) => (
            <Button
              key={syllable.text + index}
              variant="contained"
              onClick={() => {handleSyllableButtonClick(syllable.text)}}
              disabled={!gameStarted || syllable.disabled}
              sx={{
                ...buttonBaseStyle,
                width: buttonWidth,
                backgroundColor: "#3399FF",
                border: `2px solid ${currentTheme.borderColor}`,
                "&:hover": {
                  backgroundColor: isMobile ? "none": "#1c7bcc",
                  transform: isMobile ? "none" : "scale(1.05)",
                  boxShadow: isMobile ? "none" : `0 4px 8px rgba(0, 0, 0, 0.2)`,
                },
                "&.Mui-disabled": {
                  backgroundColor: currentTheme.buttonDisabledBackground,
                  color: currentTheme.buttonDisabled,
                  border: `2px solid ${currentTheme.buttonDisabled}`,
                },
              }}
            >
              {syllable.text}
            </Button>
          ))}
        </Box>

        {/* Divider */}
        <Divider
          sx={{
            width: "100%",
            backgroundColor: currentTheme.borderColor,
            margin: "5px 0",
            height: "1px",
          }}
        />

        {/* Control Buttons with Icons */}
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Button
            onClick={onShuffle}
            variant="contained"
            disabled={controlsDisabled}
            sx={{
              ...buttonBaseStyle,
              flex: "1 1 30%",
              backgroundColor: "#39b339",
              border: `2px solid ${currentTheme.borderColor}`,
              "&:hover": {
                backgroundColor: isMobile ? "none":  "#2e8c2e",
                transform: isMobile ? "none":  "scale(1.05)",
                boxShadow: isMobile ? "none":  `0 4px 8px rgba(0, 0, 0, 0.2)`,
              },
              "&.Mui-disabled": {
                backgroundColor: currentTheme.buttonDisabledBackground,
                color: currentTheme.buttonDisabled,
                border: `2px solid ${currentTheme.buttonDisabled}`,
              },
            }}
          >
            <ShuffleIcon />
          </Button>
          <Button
            onClick={onSort}
            variant="contained"
            disabled={controlsDisabled}
            sx={{
              ...buttonBaseStyle,
              flex: "1 1 30%",
              backgroundColor: "#FFC107",
              border: `2px solid ${currentTheme.borderColor}`,
              "&:hover": {
                backgroundColor: isMobile ? "none":  "#e6a806",
                transform: isMobile ? "none":  "scale(1.05)",
                boxShadow: isMobile ? "none":  `0 4px 8px rgba(0, 0, 0, 0.2)`,
              },
              "&.Mui-disabled": {
                backgroundColor: currentTheme.buttonDisabledBackground,
                color: currentTheme.buttonDisabled,
                border: `2px solid ${currentTheme.buttonDisabled}`,
              },
            }}
          >
            <SortByAlphaIcon />
          </Button>
          <Button
            onClick={onClear}
            variant="contained"
            disabled={controlsDisabled}
            sx={{
              ...buttonBaseStyle,
              flex: "1 1 30%",
              backgroundColor: "#ff3333",
              border: `2px solid ${currentTheme.borderColor}`,
              "&:hover": {
                backgroundColor: isMobile ? "none":  "#cc2929",
                transform: isMobile ? "none":  "scale(1.05)",
                boxShadow: isMobile ? "none":  `0 4px 8px rgba(0, 0, 0, 0.2)`,
              },
              "&.Mui-disabled": {
                backgroundColor: currentTheme.buttonDisabledBackground,
                color: currentTheme.buttonDisabled,
                border: `2px solid ${currentTheme.buttonDisabled}`,
              },
            }}
          >
            <ClearIcon />
          </Button>
        </Box>
      </Box>
    );
  }
};

export default ControlBox;
