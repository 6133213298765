// src/components/Puzzle.js

import React, { useEffect, useState, useRef, useContext } from "react";
import { Box, Typography } from "@mui/material";
import Loading from "../components/Loading";
import CluesContainer from "./CluesContainer";
import ControlBox from "./ControlBox";
import GameWonModal from "../components/GameWonModal";
import usePuzzleData from "../hooks/usePuzzleData";
import useTimer from "../hooks/useTimer";
import useGameState from "../hooks/useGameState";
import { useControlHandlers } from "../hooks/useControlHandlers";
import useSyllableHandlers from "../hooks/useSyllableHandlers";
import { useThemeContext } from '../contexts/ThemeContext';
import { useMediaContext } from '../contexts/MediaContext';
import { PuzzleDataContext } from '../contexts/PuzzleDataContext';
import { handleStorePuzzleStart, handleStoreCompletedPuzzle } from '../utils/utilityFunctions';
import { useNavigate, useLocation } from 'react-router-dom';
import useDailyPuzzleNumber from '../hooks/useDailyPuzzleNumber';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useSound } from '../hooks/useSound';
import { IframeContext } from '../contexts/IframeContext'; 

const Puzzle = ({
  archivePuzzleNo = null,
  packPuzzleNo = null,
  setSelectedPuzzleNo,
}) => {
  const { addPuzzleCompletion, puzzleData: completedPuzzles } = useContext(PuzzleDataContext); // renames puzzleDat to completedPuzzles
  const { isSoundOn, isDarkMode, currentTheme } = useThemeContext();
  const playSound = useSound();
  const { isMobile } = useMediaContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { height: windowHeight } = useWindowDimensions();

  const { todayPuzzleNo } = useDailyPuzzleNumber();
  const { isNotInIframe } = useContext(IframeContext); 

  const {
    puzzleData,
    puzzleType,
    loading,
    error,
    availableSyllables,
    clueStates,
    setClueStates,
    setAvailableSyllables,
    fetchPuzzleData,
  } = usePuzzleData(archivePuzzleNo, packPuzzleNo);

  const { gameStarted, setGameStarted, gameWon, setGameWon, selectedSyllable, setSelectedSyllable } = useGameState(clueStates);
  const [timer, setTimer] = useTimer(gameStarted, gameWon);
  const [controlBoxHeight, setControlBoxHeight] = useState(250);
  const [modalOpen, setModalOpen] = useState(false);
  const controlBoxRef = useRef(null);

  const currentPuzzleNumber = archivePuzzleNo || packPuzzleNo || (puzzleData ? puzzleData.puzzle_no : null);
  const localStoragePuzzleType = puzzleType === 'archive' ? 'daily' : puzzleType;

  const resetTrigger = location.state?.resetTrigger;


  const resetPuzzle = async () => {
    setClueStates([]);
    setAvailableSyllables([]);
    setSelectedSyllable(null);
    setGameStarted(false);
    setGameWon(false);
    setTimer(0);
    setModalOpen(false);

    try { 
      await fetchPuzzleData();
    } catch (error) {
      console.error("Error fetching new puzzle data:", error);
    }
  };

  useEffect(() => {
    if (resetTrigger !== undefined) {
      resetPuzzle();
    }
  }, [resetTrigger]);

  useEffect(() => {
    resetPuzzle();
  }, [archivePuzzleNo, packPuzzleNo]);

  useEffect(() => {
    if (gameWon) {
      
      try {
        if (isSoundOn) playSound("puzzle_solved");
        const completionTime = timer;
        const puzzleNumber = currentPuzzleNumber;
        const storagePuzzleType = archivePuzzleNo ? 'archive' : packPuzzleNo ? 'pack' : 'daily';

        handleStoreCompletedPuzzle(storagePuzzleType, puzzleNumber, completionTime, isDarkMode, isSoundOn);
        addPuzzleCompletion(localStoragePuzzleType, puzzleNumber, completionTime);

        console.log(completedPuzzles)

        setModalOpen(true);
      } catch (error) {
        console.error("Error adding puzzle completion:", error);
      }
    }
  }, [gameWon]);

  const handlePreviousPuzzle = () => {
    const prevPuzzleNo = currentPuzzleNumber - 1;
    if (prevPuzzleNo > 0 && setSelectedPuzzleNo) {
      if (isSoundOn) playSound("toggle");
      setSelectedPuzzleNo(prevPuzzleNo);
  
      // Call ArenaHelper.handleMidrollRequest if available
      if (!isNotInIframe && window.ArenaHelper && typeof window.ArenaHelper.getInstance === "function") {
        const arenaHelper = window.ArenaHelper.getInstance();
        if (arenaHelper && typeof arenaHelper.handleMidrollRequest === 'function') {
          arenaHelper.handleMidrollRequest();
          console.log("handleMidrollRequest called.");
        } else {
          console.error("ArenaHelper's handleMidrollRequest method is not available.");
        }
      }
    }
  };
  
  const handleNextPuzzle = () => {
    const nextPuzzleNo = currentPuzzleNumber + 1;
    if ((puzzleType === 'pack' && nextPuzzleNo <= 900) || (puzzleType === 'archive' && nextPuzzleNo < todayPuzzleNo)) {
      if (isSoundOn) playSound("toggle");
      if (setSelectedPuzzleNo) {
        setSelectedPuzzleNo(nextPuzzleNo);
  
        // Call ArenaHelper.handleMidrollRequest if available
        if (!isNotInIframe && window.ArenaHelper && typeof window.ArenaHelper.getInstance === "function") {
          const arenaHelper = window.ArenaHelper.getInstance();
          if (arenaHelper && typeof arenaHelper.handleMidrollRequest === 'function') {
            arenaHelper.handleMidrollRequest();
            console.log("handleMidrollRequest called.");
          } else {
            console.error("ArenaHelper's handleMidrollRequest method is not available.");
          }
        }
      }
    }
  };
  

  const { handleShuffle, handleSort, handleClear } = useControlHandlers(
    setClueStates,
    setAvailableSyllables,
    setSelectedSyllable,
    availableSyllables,
    selectedSyllable,
    isSoundOn
  );

  const { handleSyllableClick } = useSyllableHandlers(
    setClueStates,
    setAvailableSyllables,
    setSelectedSyllable,
    availableSyllables,
    selectedSyllable,
    isSoundOn
  );

  useEffect(() => {
    if (controlBoxRef.current) {
      let height = controlBoxRef.current.clientHeight;
  
      // Add 60px to height if puzzleType is "archive" or "pack"
      if ((puzzleType === 'archive' || puzzleType === 'pack') && gameWon) {
        height += 30;
      }
  
      setControlBoxHeight(height);
    }
  }, [availableSyllables, controlBoxRef, gameWon, puzzleType]);

    // Call handleEventChange whenever availableSyllables changes
    useEffect(() => {
      console.log("Checking ArenaHelper availability...");
      if (!isNotInIframe) {
        console.log("Not in iframe, proceeding to check ArenaHelper...");
        
        if (window.ArenaHelper && typeof window.ArenaHelper.getInstance === "function") {
          console.log("ArenaHelper found, calling getInstance...");
          const arenaHelper = window.ArenaHelper.getInstance();
    
          if (arenaHelper && typeof arenaHelper.handleEventChange === 'function') {
            console.log("handleEventChange is a function, calling it...");
            arenaHelper.handleEventChange();
            console.log("handleEventChange successfully called.");
          } else {
            console.error("handleEventChange method is not available on ArenaHelper instance.");
            console.error("ArenaHelper instance:", arenaHelper);
          }
        } else {
          console.error("ArenaHelper or getInstance is not available.");
          console.error("window.ArenaHelper:", window.ArenaHelper);
        }
      } else {
        console.log("In iframe, skipping ArenaHelper call.");
      }
    }, [availableSyllables]);
    


  const cluesContainerHeight = isMobile
  ? `calc(${windowHeight}px - ${controlBoxHeight}px - 60px)` // Subtract window height plus contol box plus padding
  : "90%";


  const isPuzzleCompleted = completedPuzzles.some(
    (entry) => entry.puzzleType === localStoragePuzzleType && entry.puzzleNumber === currentPuzzleNumber
  );

  const prevPuzzleNumber = currentPuzzleNumber - 1;
  const nextPuzzleNumber = currentPuzzleNumber + 1;

  const isPrevPuzzleCompleted = completedPuzzles.some(
    (entry) => entry.puzzleType === localStoragePuzzleType && entry.puzzleNumber === prevPuzzleNumber
  );

  const isNextPuzzleCompleted = completedPuzzles.some(
    (entry) => entry.puzzleType === localStoragePuzzleType && entry.puzzleNumber === nextPuzzleNumber
  );

  const isPrevDisabled = currentPuzzleNumber <= 1;
  const isNextDisabled =
    (puzzleType === 'pack' && currentPuzzleNumber >= 900) ||
    (puzzleType === 'archive' && currentPuzzleNumber >= todayPuzzleNo - 1);

  if (loading) {
    return <Loading message={" Puzzle"} />;
  }

  if (error) {
    return <Typography>{error}</Typography>;
  }

  if (!puzzleData) {
    return <Typography>Error loading puzzle data</Typography>;
  }

  return (
    <Box
      sx={{
        color: currentTheme.textColor,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: isMobile ? "block" : "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          overflow: "hidden",
          gap: isMobile ? "0px" : "10px",
        }}
      >
        {/* Clues Container */}
        <Box
          sx={{
            order: isMobile ? 1 : 2,
            flexGrow: isMobile ? 1 : 0,
            width: "100%",
            height: cluesContainerHeight,
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CluesContainer
            clues={clueStates}
            puzzleType={puzzleType}
            puzzleNumber={currentPuzzleNumber}
            gameStarted={gameStarted}
            timer={timer}
            selectedSyllable={selectedSyllable}
            setSelectedSyllable={setSelectedSyllable}
            onStart={() => {
              if (isSoundOn) playSound("start_puzzle");
              handleStorePuzzleStart(puzzleType, currentPuzzleNumber, isDarkMode, isSoundOn);
              setGameStarted(true);
              setSelectedSyllable("0-0");
            }}
          />
        </Box>

        {/* Control Box */}
        <Box
          ref={controlBoxRef}
          sx={{
            order: isMobile ? 2 : 1,
            position: isMobile ? "fixed" : "relative",
            bottom: isMobile ? "0px" : "auto",
            left: isMobile ? "50%" : "auto",
            transform: isMobile ? "translateX(-50%)" : "none",
            zIndex: 1000,
            paddingBottom: isMobile ? "10px" : "0px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ControlBox
            syllables={availableSyllables}
            onShuffle={handleShuffle}
            onSort={handleSort}
            onClear={handleClear}
            gameStarted={gameStarted}
            handleSyllableButtonClick={handleSyllableClick}
            gameWon={gameWon}
            puzzleType={puzzleType}
            handlePreviousPuzzle={handlePreviousPuzzle}
            handleNextPuzzle={handleNextPuzzle}
            isPrevDisabled={isPrevDisabled}
            isNextDisabled={isNextDisabled}
            prevPuzzleNumber={prevPuzzleNumber}
            nextPuzzleNumber={nextPuzzleNumber}
            isPrevPuzzleCompleted={isPrevPuzzleCompleted}
            isNextPuzzleCompleted={isNextPuzzleCompleted}
          />
        </Box>
      </Box>
      {/* Game Won Modal */}
      {puzzleData && puzzleType && (
        <GameWonModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          timer={timer}
          puzzleNumber={currentPuzzleNumber}
          puzzleType={puzzleType}
          handlePreviousPuzzle={handlePreviousPuzzle}
          handleNextPuzzle={handleNextPuzzle}
          isPrevDisabled={isPrevDisabled}
          isNextDisabled={isNextDisabled}
          prevPuzzleNumber={prevPuzzleNumber}
          nextPuzzleNumber={nextPuzzleNumber}
          isPrevPuzzleCompleted={isPrevPuzzleCompleted}
          isNextPuzzleCompleted={isNextPuzzleCompleted}
        />
      )}
    </Box>
  );
};

export default Puzzle;
