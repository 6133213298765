// src/contexts/MediaContext.js
import React, { createContext, useContext } from 'react';
import { useMediaQuery } from '@mui/material';

const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
  // Centralized media query hook
  const isMobile = useMediaQuery('(max-width:680px)');

  return (
    <MediaContext.Provider value={{ isMobile }}>
      {children}
    </MediaContext.Provider>
  );
};

// Custom hook to use the MediaContext
export const useMediaContext = () => useContext(MediaContext);
